// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/Users/okcomputer/Code/www/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-code-js": () => import("/Users/okcomputer/Code/www/src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-index-js": () => import("/Users/okcomputer/Code/www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-words-js": () => import("/Users/okcomputer/Code/www/src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */)
}

